<template>
    <vx-card :title="'View Completed Stock Relocation Items'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="readonly && id">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Inter Warehouse ID</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Source Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="this.selectedSrcWarehouse" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Dest. Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="this.selectedDestWarehouse" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Notes</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea :readonly="true" v-model="notes" width="100%" />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Attachment</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <div class="op-block mt-5">
                    <vs-list>
                        <vs-list-item title="File 1">
                            <div class="vx-input-group flex">
                                <vs-button size="small" color="success" icon-pack="feather" icon="icon-download"
                                    title="Download" class="mr-1" @click="openTab(fileUrl)" />
                            </div>
                        </vs-list-item>
                    </vs-list>
                </div>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="30%" style="vertical-align: middle;" rowspan="2">SKU Code</th>
                            <th width="25%" style="vertical-align: middle;" rowspan="2">SKU Description</th>
                            <th width="15%" style="text-align: center;" colspan="2">Source Warehouse</th>
                            <th width="15%" style="text-align: center;" colspan="2">Destination Warehouse</th>
                        </tr>
                        <tr>
                            <th width="10%">HU</th>
                            <th width="5%">Qty</th>
                            <th width="10%">HU</th>
                            <th width="5%">Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(skuLine, index) in skuLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.sku_code" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.sku_name" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.src_unit" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="number" v-model="skuLine.src_qty" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.dest_unit" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="number" v-model="skuLine.dest_qty" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";

export default {
    components: {
    },
    data() {
        return {
            id: null,
            readonly: true,
            code: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            reqDeliveryDate: null,
            eta: null,
            notes: null,
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    sku_name: null,
                    src_unit: null,
                    src_qty: 0,
                    dest_unit: null,
                    dest_qty: 0,
                }
            ],
            file: null,
            fileUrl: '',
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/outbound/sto-processing/detail-complete/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.code = resp.data.code
                    this.selectedSrcWarehouse = resp.data.selected_src_warehouse.label
                    this.selectedDestWarehouse = resp.data.selected_dest_warehouse.label
                    this.reqDeliveryDate = moment(resp.data.req_delivery_date).toDate()
                    this.eta = moment(resp.data.eta).toDate()
                    this.notes = resp.data.notes
                    this.skuLines = resp.data.sku_lines
                    this.fileUrl = resp.data.file_url
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleBack() {
            this.$router.push({
                name: "outbound.stock-transfer-order-processing",
                params: {status: 1},
            });
        },
        openTab(uri) {
            window.open(uri)
        }
    },
    watch: {
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>